import React from 'react';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Content: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={classNames('mx-auto w-full px-2 pb-5 pt-2 md:px-3 md:pb-10 md:pt-2', className)}
    >
      {children}
    </div>
  );
};

export default Content;
