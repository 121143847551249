'use client';

import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';

import Content from '@/features/home/ui/Content';
import { useScopedI18n } from '@/shared/locales/client';
import { NewButton as Button } from '@/shared/ui';

const NotFound = () => {
  const t = useScopedI18n('404');
  const router = useRouter();

  const handleGoToHomePage = () => {
    router.push('/');
  };

  return (
    <Content>
      <div className="flex flex-col justify-center pt-[84px] md:ml-[-103px] md:pt-8">
        <div className="text-center">
          <h1 className="text-text-primary md:typography-headline-1">{t('content')}</h1>
          <Image
            src="/errors/404_new.png"
            alt="Astronaut"
            className="mx-auto mt-2"
            width={427}
            height={400}
          />
          <Button
            variant="border"
            className="text-md mx-auto mt-[32px] h-[40px] w-full !rounded-2 !font-semibold md:mt-2 md:h-[54px] md:w-[400px] md:text-xl"
            onClick={handleGoToHomePage}
          >
            {t('toHomePage')}
          </Button>
        </div>
      </div>
    </Content>
  );
};

export default NotFound;
